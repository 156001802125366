import { useCallback, useContext, useEffect } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { ProfileComponent } from 'src/applications/Oversight/controls/ProfileComponent'
import { OrganizationsListPanel } from 'src/applications/Oversight/panels/Concierge/OrganizationsListPanel'
import { ImportListPanel } from 'src/applications/Oversight/panels/ImportListPanel'
import { SchemaListPanel } from 'src/applications/Oversight/panels/SchemaListPanel'
import { WaitlistPanel } from 'src/applications/Oversight/panels/WaitlistPanel'
import { WorkspaceRoutes } from 'src/applications/Oversight/routes/WorkspaceRoutes'
import { AccessDeniedScene } from 'src/applications/Oversight/scenes/AccessDeniedScene'
import { AccessKeysScene } from 'src/applications/Oversight/scenes/Account/AccessKeysScene'
import { AccountScene } from 'src/applications/Oversight/scenes/Account/AccountScene'
import { BillingScene } from 'src/applications/Oversight/scenes/Account/BillingScene'
import { WorkspacesListScene } from 'src/applications/Oversight/scenes/Common/WorkspacesListScene'
import { ImportDetailScene } from 'src/applications/Oversight/scenes/ImportDetailScene'
import { MainTeamScene } from 'src/applications/Oversight/scenes/MainTeamScene'
import { NotFoundPanel } from 'src/applications/Oversight/scenes/NotFoundScene'
import { EmbedRoutes } from 'src/applications/Oversight/scenes/Protected/EmbedRoutes'
import { SchemaDetailScene } from 'src/applications/Oversight/scenes/SchemaDetailScene'
import { TeamScene } from 'src/applications/Oversight/scenes/TeamScene'
import { BasicInfoContext } from 'src/contexts/BasicInfoContext'
import { EnvironmentContext, RefetchEnvironmentContext } from 'src/contexts/EnvironmentContext'
import { FileUploadProvider } from 'src/contexts/FileUploadContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { ContentArea } from 'src/resources/layouts/ContentArea'
import { SidebarLayout } from 'src/resources/layouts/SidebarLayout'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_ENVIRONMENT } from 'src/smart/queries/SQ_ENVIRONMENT'
import { SQ_TEAM } from 'src/smart/queries/SQ_TEAM'
import useReactRouter from 'use-react-router'
import { LogsListPanel } from 'src/applications/Oversight/panels/LogsListPanel'
import { ScheduleCalendlyMeeting } from 'src/applications/Oversight/components/ScheduleCalendlyMeeting'
import { TrialExpired } from 'src/applications/Oversight/components/TrialNoticeCard'

export const TeamRoutes = ({
  match: {
    params: { environmentSlug, teamId },
    path: teamPath
  }
}: RouteComponentProps<{ environmentSlug: string; teamId: string }>) => {
  const sessionContext = useContext(SessionContext)
  const { history } = useReactRouter()
  const { result } = useSmartQuery(SQ_TEAM, {
    fetchPolicy: 'network-only',
    variables: {
      teamId: teamId
    }
  })

  useEffect(() => {
    if (result?.testModeOnly) {
      history.replace(`/a/${teamId}/env/test`)
    }
  }, [result, teamId])

  useEffect(() => {
    sessionContext.setActiveTeamId(teamId.toString())
  }, [teamId])
  const envQuery = useSmartQuery(SQ_ENVIRONMENT, {
    variables: {
      teamId,
      slug: environmentSlug
    }
  })

  const refetchEnvironment = useCallback(() => envQuery.state.refetch(), [envQuery.state.refetch])

  if (envQuery.alert) {
    return queryAlert(envQuery)
  }

  return (
    <RefetchEnvironmentContext.Provider value={refetchEnvironment}>
      <EnvironmentContext.Provider value={envQuery.result}>
        <BasicInfoContext
          teamId={teamId}
          onTeamFoundRender={() => {
            return (
              <SidebarLayout>
                {result?.paymentExpired && (
                  <ScheduleCalendlyMeeting
                    calendlyUrl={
                      'https://calendly.com/flatfile-sales-dev/data-onboarding-consultation'
                    }
                    children={<TrialExpired />}
                    linkText='Get production ready'
                    immediatelyOpen={true}
                  />
                )}
                <FileUploadProvider>
                  <Switch>
                    {/* Workspace routes */}
                    <Route
                      component={WorkspaceRoutes}
                      path={`${teamPath}/workspaces/:workspaceId`}
                    />
                    <Route
                      component={SchemaDetailScene}
                      exact
                      path={[
                        `${teamPath}/templates/:id`,
                        `${teamPath}/templates/:id/fields/:fieldAction/:fieldId?`
                      ]}
                    />

                    {/* Portal + Embed Setup routes */}
                    <Route component={EmbedRoutes} path={`${teamPath}/setup`} />

                    {/* Team import routes */}
                    <Route component={ImportListPanel} exact path={`${teamPath}/imports`} />
                    <Route
                      component={ImportDetailScene}
                      exact
                      path={`${teamPath}/imports/:batchId`}
                    />

                    {/*<ContentArea>*/}
                    <Route component={MainTeamScene} exact path={teamPath} />

                    {/* Waitlist */}
                    <Route component={WaitlistPanel} exact path={`${teamPath}/waitlist`} />

                    {/* User routes */}
                    <Route
                      component={AccessKeysScene}
                      exact
                      path={`${teamPath}/account/access-keys`}
                    />
                    <Route component={AccountScene} exact path={`${teamPath}/account`} />

                    {/* Team routes */}
                    <Route
                      component={OrganizationsListPanel}
                      exact
                      path={`${teamPath}/organizations`}
                    />
                    <Route component={LogsListPanel} exact path={`${teamPath}/logs`} />
                    <Route component={SchemaListPanel} exact path={`${teamPath}/templates`} />
                    <Route component={WorkspacesListScene} exact path={`${teamPath}/workspaces`} />

                    <Route component={BillingScene} exact path={`${teamPath}/billing`} />

                    <Route component={TeamScene} exact path={`${teamPath}/team`} />

                    {/* Not found */}
                    <Route component={NotFoundPanel} />
                  </Switch>
                </FileUploadProvider>
                <ProfileComponent />
              </SidebarLayout>
            )
          }}
          onTrialEnded={() => (
            <SidebarLayout>
              <ContentArea>
                <Switch>
                  {/* User routes */}
                  <Route
                    component={AccessKeysScene}
                    exact
                    path={`${teamPath}/account/access-keys`}
                  />
                  <Route component={AccountScene} exact path={`${teamPath}/account`} />
                  <Route component={TeamScene} exact path={`${teamPath}/team`} />
                </Switch>
              </ContentArea>
              <ProfileComponent />
            </SidebarLayout>
          )}
          onTeamNotFoundRender={() => <AccessDeniedScene />}
        />
      </EnvironmentContext.Provider>
    </RefetchEnvironmentContext.Provider>
  )
}
