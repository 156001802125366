import { RenderedCellProps, CellChangeDetail } from '@turntable/core'

import { Atom } from './Atom'

export class BooleanAtom extends Atom<RenderedCellProps['value']> {
  /**
   * @returns a value prepared for the boolean renderer
   * @todo make the DAL server give us what we need here
   */
  public toCellValue() {
    return { label: this.value?.toString(), booleanValue: !!this.value }
  }

  /**
   * Get the primitive value from from a changeset
   * @param change
   */
  public valueFromCellChangeDetail(change: CellChangeDetail<RenderedCellProps['value']>) {
    return change.value.booleanValue === undefined ? '' : change.value.booleanValue
  }
}
